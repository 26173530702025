import React, { useMemo, useState, useEffect } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import '../styles/components/Userstable.css';
import '../styles/pages/Main.css';
import edit from "../images/edit.svg"
import { useTranslation } from "react-i18next";

const Userstable = (props) => {
    const [t, i18n] = useTranslation('common');
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setColumns([
            {
                Header: t('usersList.userId'),
                accessor: 'userId',
                sticky: 'left'
            },
            {
                Header: t('usersList.name'),
                accessor: 'name',
                sticky: 'left'
            },
            {
                Header: t('usersList.email'),
                accessor: 'email',
                sticky: 'left'
            },
            {
                width: 300,
                id: 'modifyUser',
                Header: '',
                Cell: ({ row }) => (
                    <button
                        className="btn-img-edit"
                        onClick={() => props.onModifyUser(row.values)}
                    >
                        {t('usersList.modify')}
                    </button>
                )
            },
            {
                width: 300,
                id: 'removeUser',
                Header: '',
                Cell: ({ row }) => (
                    <button
                        className="btn-img-delete"
                        onClick={() => props.onDeleteUser(row.values)}
                    >
                        {t('usersList.remove')}
                    </button>
                )
            },
            {
                Header: 'bpSupplierId',
                accessor: 'bpSupplierId',
                sticky: 'left'
            },
            {
                Header: 'supplierUserTypeCode',
                accessor: 'supplierUserType.code',
                sticky: 'left'
            }
        ]);
    }, [i18n.language, t]);
    

    function compareIgnoreCase(a, b) {
        a = a ? a : '';
        b = b ? b : '';

        let r1 = a.toLowerCase();
        let r2 = b.toLowerCase();
        if (r1 < r2) {
            return -1;
        }
        if (r1 > r2) {
            return 1;
        }

        return 0;
    }

    //const columns = COLUMNS; //useMemo(() => COLUMNS, []);
    const data = props.usersList; //; useMemo(() => props.usersList, []);
    //let sortBy = props.sortBy ? props.sortBy : [];

    let showPagination = props.usersList && props.usersList.length > 12;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            sortTypes: { //used to force sorting with no case sensitivity
                alphanumeric: (row1, row2, columnName) => {
                    return compareIgnoreCase(
                        row1.values[columnName],
                        row2.values[columnName]
                    )
                },
            },
            initialState: { pageIndex: 0, pageSize: 12, hiddenColumns: ['bpSupplierId', 'supplierUserType.code'] }
        },
        useSortBy,
        usePagination
    )

    const { pageIndex, pageSize } = state

    return (
        <>
            <div className="users-table-wrapper__table">
                <table {...getTableProps()}>
                <thead>
    {headerGroups.map(headerGroup => {
        const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
        return (
            <tr key={headerGroupKey} {...headerGroupProps}>
                {headerGroup.headers.map(column => {
                    const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                    const { key, ...rest } = headerProps;
                    return (
                        <th
                            key={key} // Explicitly apply the key
                            className={column.Header ? 'users-table-header-textcolumn' : 'users-table-header-buttoncolumn'}
                            {...rest} // Spread other props
                        >
                            <div
                                className={column.Header
                                    ? `users-table-header-textcontent ${
                                        column.isSorted
                                            ? column.isSortedDesc
                                                ? 'users-table-sorting-asc'
                                                : 'users-table-sorting-desc'
                                            : 'users-table-sorting-nosort'
                                    }`
                                    : 'users-table-header-buttoncontent'}
                            >
                                <span>{column.render('Header')}</span>
                            </div>
                        </th>
                    );
                })}
            </tr>
        );
    })}
</thead>


<tbody {...getTableBodyProps()}>
    {page.map(row => {
        prepareRow(row);
        const rowProps = row.getRowProps();
        const { key, ...rest } = rowProps;

        return (
            <tr key={key} {...rest}>
                {row.cells.map(cell => {
                    const cellProps = cell.getCellProps();
                    const { key: cellKey, ...restCellProps } = cellProps;
                    const isNormalUser = row.allCells[6]?.value === '2';
                    const isButtonCell = cell.column.id === 'removeUser' || cell.column.id === 'modifyUser';

                    return (
                        <td
                            key={cellKey}
                            className={cell.column.Header ? 'users-table-textcell' : 'users-table-buttoncell'}
                            {...restCellProps}
                        >
                            {!isButtonCell || (isButtonCell && isNormalUser) ? cell.render('Cell') : ''}
                        </td>
                    );
                })}
            </tr>
        );
    })}
</tbody>



                </table>
            </div>
            {showPagination &&
                <div className='paging-section'>
                    <div onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn--arrow-left"></div>
                    <div onClick={() => nextPage()} disabled={!canNextPage} className="btn btn--arrow-right"></div>
                    {/*
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
</button>{' '}*/}
                    <span>
                        {t('usersList.page')}{' '}
                        <strong>
                            {pageIndex + 1} {t('usersList.of')} {pageOptions.length}
                        </strong>{' '}
                    </span>
                </div>}
        </>
    )
};

export default Userstable;
